import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useClassName } from 'common/hooks';
import { ControlledTabs } from 'public/components/Tabs';

import useSlider from '../../../Home/Components/useSlider';
import HomepageAsset from '../../../Home/Components/Asset';
import CarouselArrows from '../CarouselArrows';
import CarouselControls from '../CarouselControls';
import CarouselCounter from '../CarouselCounter';
import useCustomStyle from '../../hooks';
import LPCard from '../Card';

import './carousel.less';
import 'swiper/swiper.less';

function Tabs({ setTagIndex, tagIndex, tags }) {
  const classNames = useClassName('CLPCarousel');

  if (!tags?.length) return null;

  return (
    <ControlledTabs
      names={tags}
      selectedIndex={tagIndex}
      styles={{ container: classNames('tabs') }}
      onClick={(tagName) => {
        setTagIndex(tags.findIndex((tag) => tag === tagName));
      }}
    />
  );
}

function Asset({ setPaused, slide, isActive }) {
  const classNames = useClassName('CLPCarousel');
  return (
    <div
      className={classNames('slide')}
      onMouseOver={() => setPaused(true)}
      onMouseOut={() => setPaused(false)}
      style={{
        ...(!isActive && {
          opacity: '.6',
        }),
      }}>
      <HomepageAsset asset={slide} />
    </div>
  );
}

function Card({ setPaused, slide, isActive }) {
  const classNames = useClassName('CLPCarousel');
  return (
    <div
      className={classNames('slide')}
      onMouseOver={() => setPaused(true)}
      onMouseOut={() => setPaused(false)}
      style={{
        ...(!isActive && {
          opacity: '.6',
        }),
      }}>
      <LPCard item={slide} />
    </div>
  );
}

function Slider({ component, tagIndex, paused, setPaused }) {
  const classNames = useClassName('CLPCarousel');
  const [, styles] = useCustomStyle(component.fields.settings);
  const [props, methods, state] = useSlider();

  const tags = [
    ...new Set(component.fields.slides.map((a) => a.fields.tags).flat()),
  ].filter(Boolean);
  const slides = component.fields.groupTags
    ? component.fields.slides.filter((slide) =>
        slide.fields?.tags?.includes(tags[tagIndex])
      )
    : component.fields.slides;

  return (
    <>
      <Swiper
        height={500}
        loop
        centeredSlides
        slidesPerView="auto"
        loopAdditionalSlides={slides.length}
        {...props}
        className={classNames('wrapper')}>
        {slides.map((slide, index) => (
          <SwiperSlide
            key={index}
            className={classNames('slide-container')}
            style={Object.fromEntries(
              Object.entries(styles)
                .filter(([key]) => key.startsWith('--slide'))
                .map(([key, value]) => [key.replace('--slide-', ''), value])
            )}>
            {({ isActive }) => {
              return slide.sys.contentType.sys.id === 'lpAsset' ? (
                <Asset
                  slide={slide}
                  setPaused={setPaused}
                  isActive={isActive}
                />
              ) : (
                <Card slide={slide} setPaused={setPaused} isActive={isActive} />
              );
            }}
          </SwiperSlide>
        ))}
      </Swiper>
      <CarouselControls>
        <CarouselCounter
          current={state.activeIndex + 1}
          total={slides.length}
          next={methods.slideNext}
          goTo={methods.slideTo}
          paused={paused}
          autoplayDuration={component.fields.autoplayDuration}
          type={component.fields.type}
        />
        <CarouselArrows
          next={methods.slideNext}
          prev={methods.slidePrev}
          type={component.fields.type}
        />
      </CarouselControls>
    </>
  );
}

export default function Carousel({ component }) {
  const [paused, setPaused] = useState(false);
  const [tagIndex, setTagIndex] = useState(0);

  const tags = [
    ...new Set(component.fields.slides.map((a) => a.fields.tags).flat()),
  ].filter(Boolean);

  return (
    <div style={{ position: 'relative' }}>
      <Tabs
        tagIndex={tagIndex}
        setTagIndex={(i) => {
          setTagIndex(i);
        }}
        tags={tags}
      />
      {!tags?.length ? (
        <Slider component={component} paused={paused} setPaused={setPaused} />
      ) : (
        tags.map((tag, index) => {
          return (
            <div
              key={index}
              {...(tagIndex !== index && { style: { display: 'none' } })}>
              <Slider
                component={component}
                tagIndex={index}
                paused={paused}
                setPaused={setPaused}
              />
            </div>
          );
        })
      )}
    </div>
  );
}
