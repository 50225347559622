import React, { useEffect, useRef } from 'react';

import { useClassName } from 'common/hooks';

import './carousel-counter.less';

const DELAY = 100;

function ProgressCarouselCounter({
  current,
  total,
  next,
  paused,
  autoplayDuration,
}) {
  const classNames = useClassName('CLPCarouselCounter');
  const progressRef = useRef(0);
  const ref = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (paused) return;
      if (!autoplayDuration) return;

      if (progressRef.current >= autoplayDuration) {
        next();
      } else {
        progressRef.current = progressRef.current + DELAY;
        const progress = (100 * progressRef.current) / autoplayDuration;
        ref.current.style.setProperty('width', `${progress}%`, 'important');
      }
    }, DELAY);
    return () => clearInterval(interval);
  }, [paused, autoplayDuration]);

  useEffect(() => {
    progressRef.current = 0;
    ref.current.style.setProperty(
      'width',
      `${progressRef.current}%`,
      'important'
    );
  }, [current]);

  return (
    <div className={classNames('container')}>
      {current}
      <div>
        <div ref={ref} />
      </div>{' '}
      {total}
    </div>
  );
}

function BulletCarouselCounter({
  current,
  total,
  goTo,
  paused,
  autoplayDuration,
  next,
}) {
  const classNames = useClassName('CLPBulletCarouselCounter');
  const progressRef = useRef(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (paused) return;
      if (!autoplayDuration) return;

      if (progressRef.current >= autoplayDuration) {
        next();
      } else {
        progressRef.current = progressRef.current + DELAY;
      }
    }, DELAY);
    return () => clearInterval(interval);
  }, [paused, autoplayDuration]);

  useEffect(() => {
    progressRef.current = 0;
  }, [current]);

  return (
    <div className={classNames('container')}>
      {[...Array(total).keys()].map((i) => (
        <div
          key={i}
          className={classNames(i === current - 1 && 'active')}
          onClick={() => goTo(i)}
        />
      ))}
    </div>
  );
}

export default function CarouselCounter({
  current,
  total,
  next,
  paused,
  autoplayDuration,
  type,
  goTo,
}) {
  if (type === 'Type A') {
    return (
      <ProgressCarouselCounter
        current={current}
        total={total}
        next={next}
        paused={paused}
        autoplayDuration={autoplayDuration}
      />
    );
  }

  return (
    <BulletCarouselCounter
      current={current}
      total={total}
      goTo={goTo}
      paused={paused}
      autoplayDuration={autoplayDuration}
      next={next}
    />
  );
}
