import React from 'react';
import PropTypes from 'prop-types';

import { getContentfulField } from 'common/components';
import { useClassName } from 'common/hooks';
import { isSoldOut } from 'common/helpers';
import { getItemPrice, getQuantity } from 'common/helpers';
import { ControlledTabs } from 'public/components/Tabs';

import { NewCheckoutProductOption } from '../../Components/ProductOption';
import { NewCheckoutPointsDetails, NewCheckoutRidesPoints } from '../Points';
import { NewCheckoutParkAccessDetails } from '../ParkAccessDetails';
import { isParkAccessPass } from 'public/helpers';

import './options.less';

const Options = ({
  venue,
  selectedDate,
  selectedSession,
  sessions,
  quantities,
  onChangeQuantity,
  inventory,
  forwardedRef,
  points,
  changePoints,
  isOnsitePoints,
  isParkEntryEnabled,
}) => {
  const classNames = useClassName('Options');
  const startTime = selectedSession ? selectedSession.startTime : null;
  const showAccessTicketsInfo =
    venue?.content?.showAccessTicketsInfo &&
    getContentfulField(venue?.content?.showAccessTicketsInfo);
  const parkAccessPass = inventory?.find((item) => item.parkAccessPass);

  const getClassNames = () => {
    const cls = [classNames('container')];
    if (!showOptions()) cls.push(classNames('hidden'));

    return cls.join(' ');
  };

  const showOptions = () => {
    if (!selectedDate) return false;
    if (sessions.length && !selectedSession) return false;
    if (inventory.every(isUnavailable)) return false;

    return Boolean(inventory.length);
  };

  const isUnavailable = (item) => {
    return typeof item.quantity === 'object' && !item.quantity[startTime];
  };

  const selectedOption = inventory.findIndex(
    (item) => item.name.toLowerCase() === points
  );

  const renderProducts = () => {
    const filteredInventory =
      inventory?.filter(
        (item) => !venue?.isPoints || (venue?.isPoints && !item.parkAccessPass)
      ) || [];
    const splitResidentTickets = filteredInventory?.some((item) =>
      item.name.toUpperCase().includes('NON-RESIDENT')
    );
    const groups = !splitResidentTickets
      ? { all: filteredInventory }
      : {
          residents: filteredInventory.filter(
            (item) => !item.name.toUpperCase().includes('NON-RESIDENT')
          ),
          nonResidents: filteredInventory.filter((item) =>
            item.name.toUpperCase().includes('NON-RESIDENT')
          ),
        };

    return Object.keys(groups).map((key) => (
      <>
        {!splitResidentTickets ? null : (
          <h2 className={classNames('group-label')}>
            {key === 'residents'
              ? 'NJ Resident Tickets'
              : 'Non-Resident Tickets'}
          </h2>
        )}
        {groups[key].map((item, index) => {
          if (isUnavailable(item)) return null;

          if (isOnsitePoints && points !== item.name.toLowerCase()) return null;

          return (
            <NewCheckoutProductOption
              key={item.ticketOptionId || index}
              name={item.name}
              date={item.date}
              quantity={getQuantity(item, quantities)}
              isSoldOut={isSoldOut(item, startTime)}
              description={item.note}
              price={getItemPrice(item, inventory, quantities)}
              images={[
                isOnsitePoints
                  ? { src: item.image }
                  : { contentfulField: venue.content.horizontalImage },
              ]}
              onChangeQuantity={(value) => onChangeQuantity(item, value)}
              largeImage={isOnsitePoints}
              min={item.minPurchase}
              max={item.maxPurchase}
            />
          );
        })}
      </>
    ));
  };

  return (
    <div ref={forwardedRef} className={getClassNames()}>
      {venue?.isPoints && parkAccessPass && showOptions() && (
        <>
          <h2>
            SELECT AMOUNT OF PARK ENTRY PASSES (HOW MANY PEOPLE ARE ENTERING THE
            PARK)
          </h2>
          <NewCheckoutProductOption
            key={parkAccessPass.ticketOptionId}
            name={parkAccessPass.name}
            date={parkAccessPass.date}
            quantity={getQuantity(parkAccessPass, quantities)}
            isSoldOut={isSoldOut(parkAccessPass, startTime)}
            description={parkAccessPass.note}
            price={getItemPrice(parkAccessPass, inventory, quantities)}
            images={[
              isOnsitePoints ||
              (isParkAccessPass(parkAccessPass.name) && venue?.isPoints)
                ? { src: parkAccessPass.image }
                : { contentfulField: venue.content.horizontalImage },
            ]}
            onChangeQuantity={(value) =>
              onChangeQuantity(parkAccessPass, value)
            }
            largeImage={isOnsitePoints}
            min={parkAccessPass.minPurchase}
            max={parkAccessPass.maxPurchase}
          />
        </>
      )}
      <h2>
        {isOnsitePoints
          ? 'SELECT AMOUNT OF POINTS'
          : venue?.isPoints
          ? 'ADD POINTS FOR RIDE ACCESS'
          : 'SELECT TICKET'}
      </h2>
      {isOnsitePoints && (
        <ControlledTabs
          fixed
          names={inventory
            .filter((item) => !item.parkAccessPass)
            .map((item) => item.name)}
          selectedIndex={selectedOption}
          onClick={(name) => changePoints(name.toLowerCase())}
        />
      )}
      {showOptions() && renderProducts()}
      {isOnsitePoints && (
        <>
          <NewCheckoutRidesPoints venue={venue} />
          <NewCheckoutPointsDetails />
        </>
      )}
      {showAccessTicketsInfo && !isOnsitePoints && (
        <NewCheckoutParkAccessDetails
          isPointsVenue={venue?.isPoints}
          isParkEntryEnabled={isParkEntryEnabled}
        />
      )}
    </div>
  );
};

Options.propTypes = {
  venue: PropTypes.object,
  selectedDate: PropTypes.string,
  selectedSession: PropTypes.object,
  sessions: PropTypes.array,
  quantities: PropTypes.object,
  onChangeQuantity: PropTypes.func,
  inventory: PropTypes.array,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  points: PropTypes.string,
  changePoints: PropTypes.func,
  isOnsitePoints: PropTypes.bool,
  isParkEntryEnabled: PropTypes.bool,
};

Options.defaultProps = {
  isParkEntryEnabled: false,
};

export default Options;
