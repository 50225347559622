import React from 'react';

import { useClassName } from 'common/hooks';
import { ContentfulRichText } from 'common/components';
import HomepageAsset from 'public/screens/Home/Components/Asset';

import Actions from '../Actions';
import Link from '../Link';

import './card.less';
import HomepageImage from '../../../Home/Components/Image';

function CardTypeA({ item }) {
  const classNames = useClassName('CLPCardTypeA');

  return (
    <div className={classNames('container')}>
      <HomepageAsset asset={item.fields.asset} />
      <div>
        <h4>{item.fields.title}</h4>
        {Boolean(item.fields.links.length) && (
          <Actions>
            {item.fields.links.map((link) => (
              <Link key={link.sys.id} component={link} />
            ))}
          </Actions>
        )}
      </div>
    </div>
  );
}

function CardTypeB({ item }) {
  const classNames = useClassName('CLPCardTypeB');

  return (
    <div className={classNames('container')}>
      <HomepageAsset asset={item.fields.asset} />
      <div>
        <h4>{item.fields.title}</h4>
        <p>
          <ContentfulRichText field={{ 'en-US': item.fields.description }} />
        </p>
        {Boolean(item.fields.links.length) && (
          <Actions>
            {item.fields.links.map((link) => (
              <Link key={link.sys.id} component={link} />
            ))}
          </Actions>
        )}
      </div>
    </div>
  );
}

function CardTypeC({ item }) {
  const classNames = useClassName('CLPCardTypeC');
  return (
    <div className={classNames('container')}>
      <HomepageAsset asset={item.fields.asset} />
      <div>
        <span>
          <HomepageImage field={item.fields.icon} /> {item.fields.subtitle}
        </span>
        <h4>{item.fields.title}</h4>
        <p>
          <ContentfulRichText field={{ 'en-US': item.fields.description }} />
        </p>
        {Boolean(item.fields.links.length) && (
          <Actions className={classNames('actions')}>
            {item.fields.links.map((link) => (
              <Link key={link.sys.id} component={link} />
            ))}
          </Actions>
        )}
      </div>
    </div>
  );
}

function CardTypeD({ item }) {
  const classNames = useClassName('CLPCardTypeD');
  return (
    <div className={classNames('container')}>
      <HomepageAsset asset={item.fields.asset} />
      <div>
        <span>
          <HomepageImage field={item.fields.icon} /> {item.fields.subtitle}
        </span>
        <h4>{item.fields.title}</h4>
        <p>
          <ContentfulRichText field={{ 'en-US': item.fields.description }} />
        </p>
        {Boolean(item.fields.links.length) && (
          <Actions className={classNames('actions')}>
            {item.fields.links.map((link) => (
              <Link key={link.sys.id} component={link} />
            ))}
          </Actions>
        )}
      </div>
    </div>
  );
}

function CardTypeE({ item }) {
  const classNames = useClassName('CLPCardTypeE');

  const hasLink = Boolean(item?.fields?.links?.length);
  if (!hasLink) {
    return (
      <div className={classNames('container')}>
        <HomepageAsset asset={item.fields.asset} />
      </div>
    );
  }

  return (
    <div className={classNames('container')}>
      <Link component={item.fields.links[0]}>
        <HomepageAsset asset={item.fields.asset} />
      </Link>
    </div>
  );
}

export default function Card({ item }) {
  const type = item.fields.type || 'Type A';

  const Component = {
    'Type A': CardTypeA,
    'Type B': CardTypeB,
    'Type C': CardTypeC,
    'Type D': CardTypeD,
    'Type E': CardTypeE,
  }[type];

  return <Component item={item} />;
}
