import React from 'react';

import { useClassName } from 'common/hooks';

import './carousel-arrows.less';

export default function CarouselArrows({ next, prev, type }) {
  const classNames = useClassName('CLPCarouselArrows');
  return (
    <div
      className={classNames([
        'container',
        type && type.toLowerCase().split(' ').join('-'),
      ])}>
      <div className={classNames('arrow')} onClick={prev}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none">
          <path
            d="M18.5 12H6M6 12L12 6M6 12L12 18"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className={classNames('arrow')} onClick={next}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none">
          <path
            d="M6 12L18.5 12M18.5 12L12.5 18M18.5 12L12.5 6"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
}
