import React from 'react';

import { useClassName } from 'common/hooks';
import { ContentfulRichText } from 'common/components';
import HomepageAsset from 'public/screens/Home/Components/Asset';

import Actions from '../Actions';
import Link from '../Link';

import './hero.less';

export default function Hero({ component }) {
  const classNames = useClassName('CLPHero');

  return (
    <div className={classNames('container')}>
      <HomepageAsset
        asset={component.fields.asset}
        className={classNames('media')}
        style={{ opacity: 1 }}
      />
      <div>
        <h1>{component.fields.title}</h1>
        <h2>
          <ContentfulRichText
            field={{ 'en-US': component.fields.description }}
          />
        </h2>
        <Actions>
          {component.fields.links.map((link) => (
            <Link key={link.sys.id} component={link} />
          ))}
        </Actions>
      </div>
    </div>
  );
}
