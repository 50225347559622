import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Autoplay } from 'swiper';

import { useClassName } from 'common/hooks';
import { ContentfulRichText } from 'common/components';
import HomepageAsset from 'public/screens/Home/Components/Asset';
import HomepageImage from 'public/screens/Home/Components/Image';

import Center from '../Center';
import useCustomStyle from '../../hooks';
import useSlider from '../../../Home/Components/useSlider';

import './header.less';
import 'swiper/swiper.less';
import 'swiper/modules/effect-fade/effect-fade.less';

function TypeAHeader({ component }) {
  const classNames = useClassName('CLPHeader');
  const [setRef] = useCustomStyle(component.fields.settings);

  return (
    <div
      className={classNames(['type-a', component.fields.theme])}
      ref={setRef}>
      <div>
        <span>{component.fields.subtitle}</span>
        <h3>{component.fields.title}</h3>
      </div>
      <ContentfulRichText field={{ 'en-US': component.fields.description }} />
    </div>
  );
}

function TypeBHeader({ component }) {
  const classNames = useClassName('CLPHeader');
  const [setRef] = useCustomStyle(component.fields.settings);

  return (
    <div
      className={classNames(['type-b', component.fields.theme])}
      ref={setRef}>
      <h3>{component.fields.title}</h3>
    </div>
  );
}

function TypeCHeader({ component }) {
  const classNames = useClassName('CLPHeader');
  const [setRef] = useCustomStyle(component.fields.settings);

  return (
    <div
      className={classNames(['type-c', component.fields.theme])}
      ref={setRef}>
      <h3>{component.fields.title}</h3>
    </div>
  );
}

function TypeDHeader({ component }) {
  const classNames = useClassName('CLPHeader');
  const [setRef] = useCustomStyle(component.fields.settings);

  return (
    <div
      className={classNames(['type-d', component.fields.theme])}
      ref={setRef}>
      <h3>{component.fields.title}</h3>
    </div>
  );
}

function TypeEHeader({ component }) {
  const classNames = useClassName('CLPHeaderTypeE');
  const [setRef] = useCustomStyle(component.fields.settings);
  const [props] = useSlider();

  return (
    <Center
      className={classNames(['container', component.fields.theme])}
      ref={setRef}>
      <div>
        {Boolean(component?.fields?.tags?.length) && (
          <div className={classNames('tags')}>
            {component.fields.tags.map((tag) => (
              <span key={tag}>{tag}</span>
            ))}
          </div>
        )}
        {Boolean(component.fields.subtitle) && (
          <h4>{component.fields.subtitle}</h4>
        )}
        {Boolean(component.fields.title) && <h3>{component.fields.title}</h3>}

        {Boolean(component.fields.description) && (
          <p>
            <ContentfulRichText
              field={{ 'en-US': component.fields.description }}
            />
          </p>
        )}
        {Boolean(component.fields.icon && component.fields.iconText) && (
          <span>
            <HomepageImage field={component.fields.icon} />
            <span>{component.fields.iconText}</span>
          </span>
        )}
      </div>
      <div className={classNames('slider')}>
        <Swiper
          {...(component.fields.assets.length > 1 && {
            modules: [EffectFade, Autoplay],
            effect: 'fade',
            fadeEffect: {
              crossFade: true,
            },
            autoplay: {
              delay: 5000,
            },
            loopAdditionalSlides: component.fields.assets.length,
            loop: true,
          })}
          centeredSlides
          slidesPerView="auto"
          {...props}>
          {component.fields.assets.map((asset, index) => (
            <SwiperSlide key={index}>
              <HomepageAsset
                key={asset.sys.id}
                className={classNames('item')}
                asset={asset}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Center>
  );
}

export default function Header({ component }) {
  const Component = {
    'Type A': TypeAHeader,
    'Type B': TypeBHeader,
    'Type C': TypeCHeader,
    'Type D': TypeDHeader,
    'Type E': TypeEHeader,
  }[component.fields.type];

  return <Component component={component} />;
}
