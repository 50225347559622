import React from 'react';
import PropTypes from 'prop-types';

import { useClassName } from 'common/hooks';
import pointsIcon from 'common/assets/points-ticket.svg';

import './park-access-details.less';

const ParkAccessDetails = ({ isPointsVenue, isParkEntryEnabled }) => {
  const classNames = useClassName('ParkAccessDetails');

  const title = <h3>How it works?</h3>;
  const subheader = isParkEntryEnabled ? (
    <h4>
      All Guest Entering Nickelodeon Universe Will Need Either an All Access
      Pass or Park Entry Pass
    </h4>
  ) : isPointsVenue ? (
    <h4>
      Purchase points on your mobile device now and go directly onto your
      ride(s) of choice! <b>No Wristbands Required!</b>
    </h4>
  ) : null;

  const description = isPointsVenue ? (
    !isParkEntryEnabled ? (
      <>
        <p>
          <b>Step 1:</b> Pick Date.
        </p>
        <p>
          <b>Step 2:</b> Select Quantity of Points Passes. Each Ride is either 3
          or 6 Points.
        </p>
        <p>
          <b>Step 3:</b> Complete Purchase to Receive your Digital Points Pass
          via Email. Don’t forget to save your Pass on your Apple or Google
          Wallet.
        </p>
        <p>
          <b>Step 4:</b> Redeem your Digital Point Pass directly at the ride. No
          wristband required!
        </p>
      </>
    ) : (
      <>
        <p>
          <b>Step 1:</b> Select Date.
        </p>
        <p>
          <b>Step 2:</b> Select quantity of Park Entry Passes (Number of people
          in your group who plan to enter the park).
        </p>
        <p>
          <b>Step 3:</b> Select quantity of Points. Each Ride is either 3 or 6
          points.
        </p>
        <p>
          <b>Step 4:</b> Complete Purchase to receive your mobile Points Pass +
          Park Entry Passes via email.
        </p>
        <p>
          <b>Step 5:</b> Redeem your Park Entry Pass at the Nickelodeon Universe
          ticketing counter to receive your entry wristband. Redeem your Points
          on your mobile device directly by the ride.
        </p>
      </>
    )
  ) : (
    <>
      <p>
        <b>Step 1:</b> Pick Date.
      </p>
      <p>
        <b>Step 2:</b> Choose Number of Passes.
      </p>
      <p>
        <b>Step 3:</b> Redeem your Passes for Wristbands by the Nickelodeon
        Universe Admissions Counter prior to Entering the Park.
      </p>
    </>
  );
  return (
    <div className={classNames('wrapper')}>
      <img src={pointsIcon} alt="points icon" />
      <div className={classNames('info')}>
        {title}
        {subheader}
      </div>
      <div className={classNames('description')}>{description}</div>
    </div>
  );
};

ParkAccessDetails.propTypes = {
  isPointsVenue: PropTypes.bool,
  isParkEntryEnabled: PropTypes.bool,
};

ParkAccessDetails.defaultProps = {
  isPointsVenue: false,
  isParkEntryEnabled: false,
};

export default ParkAccessDetails;
