import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { sortBy } from 'lodash';

import { useClassName } from 'common/hooks';
import { filterDateEvents } from '../utils';
import NewEventCard from './NewEventCard';
import ViewTypeOptions from './ViewTypeOptions';

import './event-list-view.less';

const isHappeningToday = (schedule) => {
  const today = DateTime.fromObject({
    zone: 'America/New_York',
  }).startOf('day');

  if (schedule.startDate > today || schedule.endDate < today) return false;

  const weekday = today.toFormat('EEEE').toLocaleLowerCase();

  return Boolean(
    schedule.weekdays.find((day) => day.weekday === weekday && !day.isClosed)
  );
};

const isPast = (schedule) => {
  const today = DateTime.fromObject({
    zone: 'America/New_York',
  }).startOf('day');

  return today > schedule.endDate;
};

const getScheduleHeader = (schedule) => {
  if (isHappeningToday(schedule)) return 'Today';

  const today = DateTime.fromObject({
    zone: 'America/New_York',
  }).startOf('day');

  if (schedule.startDate < today) {
    return today.toFormat('LLLL');
  }

  return schedule.startDate.toFormat('LLLL');
};

const groupEvents = (events) => {
  return events.reduce((grouped, event) => {
    event.schedules.forEach((schedule) => {
      if (isPast(schedule)) return;
      const header = getScheduleHeader(schedule);
      const existing = grouped.find((g) => g.header === header);
      if (existing) {
        const existingSchedule = existing.schedules.find(
          (a) =>
            a.startDate.equals(schedule.startDate) &&
            a.endDate.equals(schedule.endDate)
        );
        if (existingSchedule) {
          existingSchedule.events.push({
            ...event,
            weekdays: schedule.weekdays,
          });
        } else {
          existing.schedules.push({
            startDate: schedule.startDate,
            endDate: schedule.endDate,
            events: [{ ...event, weekdays: schedule.weekdays }],
          });
        }
      } else {
        grouped.push({
          header: header,
          schedules: [
            {
              startDate: schedule.startDate,
              endDate: schedule.endDate,
              events: [{ ...event, weekdays: schedule.weekdays }],
            },
          ],
        });
      }
    });

    return grouped;
  }, []);
};

const EventSchedules = ({ schedules, setSelected, selectedDate }) => {
  const classNames = useClassName('EventListView');

  return sortBy(schedules, ['startDate']).map((schedule, index) => {
    const oneDayEvent = schedule.startDate.equals(schedule.endDate);

    return (
      <div key={index} className={classNames('container')}>
        <div className={classNames('dates')}>
          <div className={classNames('date')}>
            <h3>{schedule.startDate.monthShort.toUpperCase()}</h3>
            <h2>{schedule.startDate.day}</h2>
          </div>
          {!oneDayEvent && (
            <div className={classNames('date')}>
              <h3>{schedule.endDate.monthShort.toUpperCase()}</h3>
              <h2>{schedule.endDate.day}</h2>
            </div>
          )}
        </div>
        <div className={classNames('event-card')}>
          {schedule.events.map((event) => {
            return (
              <NewEventCard
                key={event._id}
                event={event}
                setSelected={() => setSelected({ ...event, schedule })}
                selectedDate={selectedDate}
              />
            );
          })}
        </div>
      </div>
    );
  });
};

const EventListView = ({
  events,
  setSelected,
  selectedDate,
  viewType,
  handleViewTypeChange,
}) => {
  const classNames = useClassName('EventListView');
  const filteredEvents = filterDateEvents(selectedDate, events);
  const grouped = groupEvents(filteredEvents);
  const todaySchedules = grouped.find(({ header }) => header === 'Today');
  const upcomingSchedules = grouped.filter(({ header }) => header !== 'Today');

  return (
    <>
      {todaySchedules && (
        <div
          className={classNames(['wrapper', 'today'])}
          key={todaySchedules.header}>
          {!selectedDate && (
            <div className={classNames('header-container')}>
              <h2 className={classNames('wrapper-title')}>
                {todaySchedules.header}
              </h2>
              <ViewTypeOptions
                viewType={viewType}
                handleViewTypeChange={handleViewTypeChange}
              />
            </div>
          )}
          <EventSchedules
            schedules={todaySchedules.schedules}
            setSelected={setSelected}
            selectedDate={selectedDate}
          />
        </div>
      )}
      {Boolean(upcomingSchedules?.length) && (
        <div
          className={classNames(['wrapper', 'upcoming'])}
          key="Upcoming Events">
          {!selectedDate && (
            <div className={classNames('header-container', 'no-border')}>
              <h2 className={classNames('wrapper-title')}>Upcoming Events</h2>
              {!todaySchedules && (
                <ViewTypeOptions
                  viewType={viewType}
                  handleViewTypeChange={handleViewTypeChange}
                />
              )}
            </div>
          )}

          {sortBy(
            upcomingSchedules,
            ({ schedules }) => schedules[0].startDate
          ).map(({ header, schedules }) => (
            <div key={header}>
              {!selectedDate && (
                <h3 className={classNames('month')}>{header}</h3>
              )}
              <EventSchedules
                schedules={schedules}
                setSelected={setSelected}
                selectedDate={selectedDate}
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

EventListView.propTypes = {
  events: PropTypes.array,
  setSelected: PropTypes.func,
  selectedDate: PropTypes.object,
  viewType: PropTypes.string,
  handleViewTypeChange: PropTypes.func,
};

export default EventListView;
