import React from 'react';
import { useHistory } from 'react-router-dom';

import { useClassName } from 'common/hooks';
import { track } from 'utils/analytics';

import HomepageImage from 'public/screens/Home/Components/Image';
import useCustomStyle from '../../hooks';

import './link.less';

export default function Link({ component, children }) {
  const classNames = useClassName('CLPLink');
  const [setRef] = useCustomStyle(component.fields.settings);
  const history = useHistory();

  const onClick = (e) => {
    if (component.fields.type === 'external') {
      window.open(component.fields.url, '_blank');
    } else {
      history.push(component.fields.url);
    }
    track('Redirect', { url: component.fields.url });
    e.preventDefault();
  };

  if (children) {
    return (
      <a href={component.fields.url} onClick={onClick}>
        {children}
      </a>
    );
  }
  return (
    <a
      href={component.fields.url}
      ref={setRef}
      onClick={onClick}
      className={classNames(['container', component.fields.theme])}>
      {component.fields.label}
      {Boolean(component.fields.icon) && (
        <HomepageImage field={component.fields.icon} />
      )}
    </a>
  );
}
