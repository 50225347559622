import { useRef } from 'react';
import { useHistory } from 'react-router';

import { useWindowSize } from 'common/hooks';
import { track } from 'utils/analytics';

export default function useCustomStyle(settings) {
  const viewport = useWindowSize();

  const style = Object.fromEntries(
    settings
      ? settings
          .map(({ fields, sys }) => {
            const type = sys.contentType.sys.id;
            if (fields.key === '--background-image') {
              return [
                [
                  'background',
                  `url(${fields.value}), linear-gradient(to bottom, #F3D8AB, #F3D8AB)`,
                ],
                ['background-blend-mode', 'multiply'],
                ['background-repeat', 'no-repeat'],
                ['background-size', 'contain'],
              ];
            }
            if (type === 'keyValue') {
              return [[fields.key, fields.value]];
            }

            return [
              [
                fields.key,
                `${
                  (viewport.isMobile && fields.mobileValue) ||
                  (viewport.isTablet && fields.tabletValue) ||
                  (viewport.isDesktop && fields.desktopValue)
                }`,
              ],
            ];
          })
          .flat()
      : []
  );

  return [
    (node) => {
      if (node) {
        for (const [key, value] of Object.entries(style)) {
          node.style.setProperty(key, value, 'important');
        }
      }
    },
    style,
  ];
}

export function useTrackRedirect(url) {
  const ref = useRef(0);
  const history = useHistory();

  const handleMouseDown = (e) => {
    ref.current = e.screenX;
  };

  const handleClick = (e, func) => {
    const delta = Math.abs(e.screenX - ref.current);

    ref.current = 0;
    if (delta > 10) {
      e.preventDefault();
    } else {
      func();
    }
  };

  if (!url) return {};

  return {
    onMouseDown: handleMouseDown,
    onClick: (e) =>
      handleClick(e, () => {
        track('Redirect', { url: url });
        if (url.startsWith('http')) {
          window.open(url, '_blank');
        } else {
          history.push(url);
        }
      }),
    style: {
      cursor: 'pointer',
    },
  };
}
